import axios from "axios";
import { getURL } from "./Service";

export const loginUser = async ({user,password}) => {
    try {
        return await axios.post(getURL() + "api/auth/authenticate", {
            user,
            password,
        });
    } catch (error) {
        throw error;
    }
}