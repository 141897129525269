import React from 'react'


function Exception() {
    const { host, protocol } = window.location;
    window.location.href = protocol + "//" + host + "/login";
  return (
    <>
    </>
  );
}

export default Exception