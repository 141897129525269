import React from 'react'
import { newUser } from '../../service/Service';

function NewUser() {

    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());
      await newUser(data);
      const { host, protocol } = window.location;
      window.location.href = protocol + "//" + host + "/users";
    };

  return (
    <>
      <div className="container justify-content-center">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <input
              type="text"
              name="user"
              placeholder="Nombre de usuario"
              className="form-control"
              required
            />
          </div>
          <div className="row mb-3">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Contraseña"
              required
            />
          </div>
          <div className="row mb-3">
            <input
              type="text"
              name="firstName"
              placeholder="Nombre"
              className="form-control"
              required
            />
          </div>
          <div className="row mb-3">
            <input
              type="text"
              name="lastName"
              placeholder="Apellidos"
              className="form-control"
              required
            />
          </div>

          <input
            type="submit"
            value="Añadir"
            className="btn btn-outline-warning"
          />
        </form>
      </div>
    </>
  );
}

export default NewUser