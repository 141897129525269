import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { initBBDD, ping } from '../../service/Service';
import { ClimbingBoxLoader } from 'react-spinners';

const initialLoginFrom = {
    user: '',
    password: '',
}

function Login({handlerLogin}) {
  const {pathname} =
    window.location;
    if( pathname !== '/login'){
      Swal.fire("Faltan credenciales", "No tiene acceso al recurso", "error");
    }
  const [loginFrom, setLoginFrom] = useState(initialLoginFrom);
  const { user, password } = loginFrom;

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setLoginFrom({
      ...loginFrom,
      [name]: value,
    });
  };

  const onSubmit = async(event) => {
    event.preventDefault();
    if(ping()){
      document.getElementById("loading").hidden = false;
      document.getElementById("modal-login").hidden = true;
      await initBBDD();
      setTimeout(() => {
        document.getElementById("loading").hidden = true;
        document.getElementById("modal-login").hidden = false;
        if (!user || !password) {
          Swal.fire("Error de Validación", "User y password requeridos", "error");
          console.log(loginFrom);
        }
        handlerLogin({ user, password });
        setLoginFrom(initialLoginFrom);
      }, 30000);

    }else{
      const { host, protocol } = window.location;
      window.location.href = protocol + "//" + host;
    }
      
  };
  return (
    <>
      <div className="container center" id="loading" hidden>
        <ClimbingBoxLoader color="#FF6B35" size={75} />
        <div className="row">
          <h5 style={{ color: "#FF6B35" }}>30 s</h5>
        </div>
      </div>
      <div
        className="modal mt-5 pt-5"
        style={{ display: "block" }}
        tabIndex="-1"
        id="modal-login"
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{ backgroundColor: "#f1f1df", color: "#004E89" }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Login</h5>
            </div>
            <form onSubmit={onSubmit}>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control my-3 w-75"
                  placeholder="User"
                  name="user"
                  value={user}
                  onChange={onInputChange}
                />
                <input
                  type="password"
                  className="form-control my3 w-75"
                  placeholder="Contraseña"
                  name="password"
                  value={password}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn delete-btn">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login