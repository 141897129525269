import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CircleLoader } from 'react-spinners';
import { getURL, users } from '../../service/Service';
import User from '../component/User';
import { Link } from 'react-router-dom';

function Users() {
    const [data, setData] = useState([]);
    useEffect(() => {
      getData();
    }, []);

    const getData = async() =>{
      document.getElementById("loading").hidden = false;
      document.getElementById("body_load").hidden = true;
      const response = await users();
      setData(response.data);
      document.getElementById("loading").hidden = true;
      document.getElementById("body_load").hidden = false;
    };
  return (
    <>
      <div className="container justify-content-center">
        <div className="center" id="loading" hidden>
          <CircleLoader color="#FF6B35" size={100} />
        </div>
        <div className="container m-2" id="body_load">
          <table className="table table-info table-striped">
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Nombre y apellidos</th>
                <th scope="col">Correo</th>
                <th scope="col" width="10%"></th>
                <th scope="col" width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id}>
                  <User
                    user={user.username}
                    name={user.name}
                    last_name={user.lastName}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          <Link to={`/newUser`}>
            <button className="btn edit-btn">Nuevo usuario</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Users