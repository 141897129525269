import axios from 'axios';
import React from 'react'
import { getConfig, getURL } from '../../service/Service';

function User({user, name, last_name, mail}) {

  const handelDeleteUser = async() => {
    try {
      console.log('delete');
      await axios.post(getURL()+ 'admin/delete',{
        user: user,
        password: name
      }, getConfig());
      window.location.href = window.location.href;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <td>{user}</td>
      <td>
        {name}, {last_name}
      </td>
      <td>@mail(por implementar)</td>
      <td> 
        <button className="btn edit-btn-user">Editar</button>
      </td>
      <td>
        <button className="btn delete-btn-user" onClick={handelDeleteUser}> Eliminar</button>
      </td>
    </>
  );
}

export default User