import React, { useEffect, useRef} from 'react'
import { CircleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { getAllData, update } from '../service/Service';
import { overwriteData } from '../firebase/write';

function Edit() {
    const recipe = JSON.parse(localStorage.getItem("recipe")) || null;
    const ingredients = JSON.parse(localStorage.getItem("ingredients")) || null;
    console.log(ingredients);
    let divIngredients = [];
    const initialized = useRef(false);
    useEffect(()=>{
        if (!initialized.current) {
          initialized.current = true;

          document.getElementById("quantity").value = ingredients.length;
          console.log(document.getElementById("quantity").value);
          let div_ingredients = document.getElementById("divIngre").innerHTML;
          for (let i = 0; i < ingredients.length; i++) {
            div_ingredients += `<div class="row mb-3" id="div${i + 1}">
        <div class="col-3">
        Nombre <input type="text" id="name[${i}]" value = ${
              ingredients[i].name
            }>
        </div>
        <div class="col-3">
        Cantidad <input type="text" id="amount[${i}]" value = ${
              ingredients[i].amount
            }>
        </div>
        <div class="col-3">
        Medida <input type="text" id="measure[${i}]" value = ${
              ingredients[i].measure
            }>
        </div>
        </div>`;
          }

          document.getElementById("divIngre").innerHTML = div_ingredients;
        }
    },[]);

    

    const handelQuantity = (event) => {
      document.getElementById("minus").hidden = false;
      document.getElementById("quantity").value++;
      let aux = document.getElementById("quantity").value;
      if (aux > 1) {
        //save data_inputs
        const div_ingredient = {};
        div_ingredient.name = document.getElementById(`name[${aux - 2}]`).value;
        div_ingredient.measure = document.getElementById(
          `measure[${aux - 2}]`
        ).value;
        div_ingredient.amount = document.getElementById(
          `amount[${aux - 2}]`
        ).value;
        divIngredients.push(div_ingredient);
      }

      //Get all innerHtml
      let div_ingredients = document.getElementById("divIngre").innerHTML;

      const quantity = document.getElementById("quantity").value;
      // Add one more row
      div_ingredients += `<div class="row mb-3" id="div${quantity}">
        <div class="col-3">
        Nombre <input type="text" id="name[${quantity - 1}]" >
        </div>
        <div class="col-3">
        Cantidad <input type="text" id="amount[${quantity - 1}]" >
        </div>
        <div class="col-3">
        Medida <input type="text" id="measure[${quantity - 1}]" >
        </div>
        </div>`;

        
      document.getElementById("divIngre").innerHTML = div_ingredients;

      //fill all data
      for (let i = 0; i < quantity - 1; i++) {
        document.getElementById(`name[${i}]`).value = divIngredients[i].name;
        document.getElementById(`measure[${i}]`).value =
          divIngredients[i].measure;
        document.getElementById(`amount[${i}]`).value =
          divIngredients[i].amount;
      }
    };

    const handelMinusQuantity = () => {
      const quantity = document.getElementById("quantity").value;
      const lastElement = document.getElementById(`div${quantity}`);
      lastElement.remove();
      document.getElementById("quantity").value--;
      divIngredients.pop();
      if (quantity < 2) {
        document.getElementById("minus").hidden = true;
      }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        Swal.fire({
          title: `¿Seguro que quieres guardar los cambios?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Guardar",
          denyButtonText: `Cancelar`,
        }).then( async(result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const quantity = document.getElementById("quantity").value;

            const formData = new FormData(event.target);

            const data = Object.fromEntries(formData.entries());
            data.ingredients = [];
            for (let i = 0; i < quantity; i++) {
              const ingredient = {};
              ingredient.name = document.getElementById(`name[${i}]`).value;
              ingredient.measure = document.getElementById(
                `measure[${i}]`
              ).value;
              ingredient.amount = document.getElementById(`amount[${i}]`).value;
              data.ingredients.push(ingredient);
            }
            console.log(data);
            document.getElementById("loading").hidden = false;
            console.log(recipe.id);
            
            console.log(parseInt(recipe.id));
            await update({id:parseInt(recipe.id), data});
            const response = await getAllData();
            await overwriteData(response.data);
            const { host, protocol } = window.location;
            window.location.href = protocol + "//" + host + "/visualize/"+recipe.id;
          } else if (result.isDenied) {
            Swal.fire(`No se ha modificado ${recipe.name}`, "", "info");
          }
        });
      
    };
  return (
    <>
      <div className="container-fluid">
        <div className="center" id="loading" hidden>
          <CircleLoader color="#FF6B35" size={100} />
        </div>
        <div className="row">
          <h3>Editar receta</h3>
        </div>
        <div className="row mb-4">
          <form onSubmit={handleSubmit}>
            <div className="row mb-2">
              <label htmlFor="name" className="text-start">
                Nombre:
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Entra el nombre de la receta"
                defaultValue={recipe.name}
                required
              />
            </div>
            <h6 className="text-center mb-3 mt-5">Ingredientes</h6>
            <div id="divIngre"></div>
            <div className="row">
              <input type="number" id="quantity" hidden />
              <div className="row mb-2">
                <div className="col" id="minus">
                  <button
                    type="button"
                    className="btn text-end"
                    onClick={handelMinusQuantity}
                  >
                    <i className="fa fa-minus-circle" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="col text-end">
                  <button
                    type="button"
                    className="btn text-end"
                    onClick={handelQuantity}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="category" className="mb-2">
                Categoría:
              </label>
              <select name="category" id="lang" defaultValue={recipe.category}>
                <option value="Meat">Meat</option>
                <option value="Fish">Fish</option>
                <option value="Breakfast">Breakfast</option>
                <option value="Dessert">Dessert</option>
                <option value="Mixed">Mixed</option>
                <option value="Legumes">Legumes</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="steps" className="mb-2">
                Pasos:
              </label>
              <textarea
                name="steps"
                className="form-control"
                placeholder="Pasos de la nueva receta"
                defaultValue={recipe.steps} required
              ></textarea>
            </div>

            <input
              type="submit"
              value="Guardar"
              className="btn btn-outline-warning"
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default Edit