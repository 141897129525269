import { Link,  useParams } from "react-router-dom";
import { getAllData, getURL, ping, remove } from "../service/Service";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import Ingredients from "../component/Ingredients";
import { CircleLoader } from "react-spinners";
import { useAuth } from "../auth/hooks/useAuth";
import Swal from "sweetalert2";
import globalData from "../firebase/globalData";
import { overwriteData } from "../firebase/write";


function VisualizeRecipe() {
  const { login } = useAuth();
  const { id } = useParams();
  const [recipe, setRecipe] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const index = globalData.findIndex((x) => x.id === parseInt(id));
  const isconnected = ping();
  useEffect(() => {
     //getRecipeData();
  }, []);

  /*const getRecipeData = async() => {
   document.getElementById("loading").hidden = false;
   if (isconnected) {
     const responserecipe = await axios.get(getURL() + "get/recipe/" + id);
     setRecipe(responserecipe.data);
     const responseIngre = await axios.get(
       getURL() + "get/recipe/" + id + "/ingredients"
     );
     setIngredients(responseIngre.data);
   }
    document.getElementById("loading").hidden = true;
  };*/
 
  const handlerDelete = (id) =>{
    Swal.fire({
      title: `¿Seguro que quieres eliminar la receta ${recipe.name}?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        document.getElementById("loading").hidden = false; 
        await remove(id, login.user.user);
        const response = await getAllData();
        await overwriteData(response.data);
        console.log(response.data);
        const { host, protocol } = window.location;
        window.location.href = protocol + "//" + host +"/visualize";
      } else if (result.isDenied) {
        Swal.fire(`No se ha eliminado ${globalData[index].name}`, "", "info");
      }
    });
  };
    

  return (
    <>
      <div className="container text-center justify-content-center">
        <div className="center" id="loading" hidden>
          <CircleLoader color="#FF6B35" size={100} />
        </div>
        <div className="row mt-5 mb-5 justify-content-center" id="rest-body">
          <div className="card recipe_name w-auto shadow mb-5 bg-body rounded">
            <div className="card-body">
              <h5>{globalData[index]?.name}</h5>
            </div>
          </div>
          <>
            {
              <Ingredients
                ingredients={globalData[index]?.ingredients
                }
              />
            }
          </>

          <div className="row mt-5 justify-content-center mb-2">
            <div className="card recipe_name w-auto shadow mt-3 bg-body rounded">
              <div className="card-body">
                <p style={{ textAlign: "left" }}>
                  <pre>
                    {globalData[index]?.steps}
                  </pre>
                </p>
              </div>
            </div>
          </div>
        </div>
        {login.isAuth ? (
          <div className="mt-5 mb-5">
            <Link
              to={"/edit"}
              onClick={() => {
                localStorage.setItem(
                  "recipe",
                  JSON.stringify(globalData[index])
                );
                localStorage.setItem(
                  "ingredients",
                  JSON.stringify(globalData[index]?.ingredients)
                );
              }}
            >
              <button type="button" className="btn edit-btn me-3">
                Editar
              </button>
            </Link>
            <button
              type="button"
              className="btn delete-btn"
              onClick={() => handlerDelete(id)}
            >
              Eliminar
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default VisualizeRecipe;
