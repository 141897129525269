import React from "react";
import { Link } from "react-router-dom";
import { ping } from "../service/Service";
import { saveData } from "../firebase/write";

function Home() {
const connected = ping();
//saveData();
  return (
    <>
      <div className="container text-center">
        <div className="mb-5">
          <h3>
            {!connected ? (
              <pre>
                Base de datos offline 22 - 8 (UTC+1) , solo se permite
                visualizar las recetas.
              </pre>
            ) : (
              <>Bienvenido</>
            )}
          </h3>
        </div>

        <Link to="/visualize" className="link">
          <h4>Visualizar Recetas</h4>
        </Link>
      </div>
    </>
  );
}

export default Home;
