import app from "./firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";


export const readData = async() => {
    const db = getDatabase(app);
    const dbRef = ref(db,"api/recipes");
    const snapshot = await get(dbRef);
    if(snapshot.exists()){
        return Object.values(snapshot.val());
    }else{
        return [];
    }
}