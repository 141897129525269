import React, { useEffect, useState } from "react";
import { RecipeCard } from "../component/RecipeCard";
import { Link, useParams } from "react-router-dom";
import DropdownCategory from "../component/DropdownCategory";
import { getRecipes, getRecipesByName, getRecipesByNameIngredient, ping, recipeUser } from "../service/Service";
import { CircleLoader } from "react-spinners";
  

function Visualice({login}) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  
  useEffect(() => {
      if(id === undefined){
        getData();
      }else if (id === login.user?.user) {
        getDataUser();
      } else {
        getDataByName();
      }
  }, []);

  const getData = async () =>{
    document.getElementById("loading").hidden = false;
    document.getElementById("drop_menu").hidden = true;
    const response = await getRecipes();
    setData(response); 
    document.getElementById("loading").hidden = true;
    document.getElementById("drop_menu").hidden = false;
  }
  const getDataUser = async () => {
    document.getElementById("loading").hidden = false;
    document.getElementById("drop_menu").hidden = true;
    // por implementar en api
    if(ping()){
    const response = await recipeUser({id});
    console.log(response);
    setData(response.data);
    }else{
      const response = await getRecipes();
      setData(response);
    }
    document.getElementById("loading").hidden = true;
    document.getElementById("drop_menu").hidden = false;
  };

  const getDataByName = async () =>{
    document.getElementById("loading").hidden = false;
    document.getElementById("drop_menu").hidden = true;
    const response = await getRecipesByName(id);
    const responeIngredient = await getRecipesByNameIngredient(id);
    const mergedArray = response.concat(
      responeIngredient.filter(
        (item2) => !response.some((item1) => item1.id === item2.id)
      )
    );
    //console.log(mergedArray);
    setData(mergedArray);
    document.getElementById("loading").hidden = true;
    document.getElementById("drop_menu").hidden = false;
  }

  
  return (
    <>
      <div className="container text-center">
        <div className="center" id="loading">
          <CircleLoader color="#FF6B35" size={100} />
        </div>
        <div className="container my-2">
          <div className="row" id="drop_menu">
            <DropdownCategory />
          </div>
          <div className="row mt-5">
            {data.map((recipe) => (
              //<div>{recipe.id}</div>
              <div className="col-sm-6 col-xs-12 my-2" key={recipe.id}>
                <Link
                  to={`/visualize/${recipe.id}`}
                  className="link_card"
                >
                  <RecipeCard name={recipe.name} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Visualice;
