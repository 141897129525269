import React from "react";
import { Link } from "react-router-dom";

function DropdownCategory() {
  return (
    <>
      <div className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Categoría
        </button>
        {/*Breakfast, Meat, Fish, Dessert, Mixed, Legumes*/}
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <Link className="dropdown-item" to="/visualize/c/0" reloadDocument>
              Breakfast
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/visualize/c/1" reloadDocument>
              Meat
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/visualize/c/2" reloadDocument>
              Fish
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/visualize/c/3" reloadDocument>
              Dessert
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/visualize/c/4" reloadDocument>
              Mixed
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/visualize/c/5" reloadDocument>
              Legumes
            </Link>
          </li>
          <div className="dropdown-divider"></div>
          <li>
            <Link className="dropdown-item" to="/visualize" reloadDocument>
              All
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DropdownCategory;
