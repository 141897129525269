import React from "react";
import Ingredient from "./Ingredient";

function Ingredients({ingredients}) {
  return (
    <>
      <div>
        <h5>Ingredientes</h5>
      </div>
      {ingredients.map((ingredient) => (
        <div className="col-sm-6 col-xs-12 my-4" key={ingredient.name}>
          <Ingredient Ingredient={ingredient} />
        </div>
      ))}
    </>
  );
}

export default Ingredients;
