import React from 'react'
import "./styles.css";
import { Row, Col } from "react-bootstrap";
import { BsLinkedin, BsInstagram, BsGithub, BsCCircle } from "react-icons/bs";

function Footer() {
  return (
    <footer>
      <div className="foot_container">
        <Row className="justify-content-md-center">
          <Col xs={1} className="text-end">
            <ul className="list-unstyled icons">
              <li>
                <a href="">
                  <BsLinkedin />
                </a>
              </li>
              <li>
                <a href="">
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a href="">
                  <BsGithub />
                </a>
              </li>
            </ul>
          </Col>
          <Col s={3}>
            <ul className="list-unstyled list">
              <li className="li_tittle">
                <a href="#">Paul G Lacalle</a>
              </li>
              <li>
                <a href="#">Git Projects</a>
              </li>
              <li>
                <a href="#">Photography</a>
              </li>
              <li>
                <a href="https://recipesweb.thestreetportfolio.com">
                  Food Recipes
                </a>
              </li>
            </ul>
          </Col>
          <Col sm={3} className="text-center py-4">
            <a href="/about" className="contact">
              <h5>Contact</h5>
            </a>
          </Col>
          <Col s={3} className="text-end">
            <ul className="list-unstyled list">
              <li className="li_tittle">
                <a href="https://jr2210.github.io/">George R La Calle</a>
              </li>
              <li>
                <a href="https://github.com/JR2210">Git Projects</a>
              </li>
            </ul>
          </Col>
          <Col xs={1}>
            <ul className="list-unstyled icons">
              <li>
                <a href="">
                  <BsLinkedin />
                </a>
              </li>
              <li>
                <a href="">
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a href="">
                  <BsGithub />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="text-center py-5">
          <span
            style={{
              fontSize: "80%",
              color: "#1D3557",
              textDecoration: "underline",
            }}
          >
            <BsCCircle className='me-1'/>
            the street family
          </span>
        </Row>
      </div>
    </footer>
  );
}

export default Footer