import React from "react";

function Ingredient({ Ingredient }) {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="row">
            <div className="col-auto ingredient_text rounded me-1">
              {Ingredient.name}
            </div>
            x
            <div className="col-auto ingredient_text rounded ms-1 me-1">
              {Ingredient.amount}
            </div>
            <div className="col-auto ingredient_text rounded">
              {Ingredient.measure}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ingredient;
